import { Col, Container, Row } from "reactstrap";
import img1 from "../backgrounds/yazilimDanismanlik.jpeg";
import img2 from "../backgrounds/egitim.jpg";
import img3 from "../backgrounds/yazilimDestek.jpg";
import img4 from "../backgrounds/webGelsitirme.jpg";
import img5 from "../backgrounds/androidgelistirme.jpg";
import { MainContext, useContext } from "../Context/MainContext";
import { useState, useEffect } from "react";
function ProductsComponent() {

  const { lan,  } = useContext(MainContext);

  var [card1, setCard1] = useState(0);
  var [card2, setCard2] = useState(1);
  var [card3, setCard3] = useState(2);



  const productionNext = () => {
    switch (card1) {
      case 2:
        setCard1(card1 += 1);
        setCard2(card2 += 1);
        setCard3(0);
        break;
      case 3:
        setCard1(card1 += 1);
        setCard2(0);
        setCard3(card3 += 1);
        break;
      case 4:
        setCard1(0);
        setCard2(card2 += 1);
        setCard3(card3 += 1);
        break;
      default:
        setCard1(card1 += 1);
        setCard2(card2 += 1);
        setCard3(card3 += 1);
        break;
    }
  };

  
  const productionPrior = () => {
    let newCard1 = card1;
    let newCard2 = card2;
    let newCard3 = card3;

    switch (card1) {
      case 3:
        newCard1 -= 1;
        newCard2 -= 1;
        newCard3 = 4;
        break;
      case 4:
        newCard1 -= 1;
        newCard3 -= 1;
        newCard2 = 4;
        break;
      case 0:
        newCard3 -= 1;
        newCard2 -= 1;
        newCard1 = 4;
        break;
      default:
        newCard1 -= 1;
        newCard2 -= 1;
        newCard3 -= 1;
        break;
    }
    setCard1(newCard1);
    setCard2(newCard2);
    setCard3(newCard3);
    
  }


  const [photo, setPhoto] = useState([
    img1,
    img2,
    img3,
    img4,
    img5]);

  const [boshliqEn] = useState([
    "Consultancy",
    "Education",
    "Technical support",
    "Website",
    "Apps"
  ]);
  const [boshliqRu] = useState([
    "Kонсультирование",
    "Образование",
    "Техническая поддержка",
    "Bеб-сайт",
    "Приложение для телефона"
  ]);
  const [boshliqTr] = useState([
    "Danışmanlık",
    "Eğitim",
    "Teknik Destek",
    "Web-site",
    "Uygulama"
  ]);
  const [boshliqUz] = useState([
    "Konsalting",
    "Ta'lim",
    "Texnik yordam",
    "Web-sayt",
    "Ilovalar"
  ]);
  const [textEn, setTextEn] = useState([
    "Would you like to increase efficiency and optimize costs in your business by taking advantage of our software consultancy service?",
    "Would you like to be trained in software fields to make you more successful in your job?",
    "Would you like to get technical assistance to become more experienced and effective in software fields?",
    "Would you like to provide better service to your customers by developing web applications?",
    "Would you like to provide better service to your customers by developing Android applications?"
  ]);
  const [textRu, setTextRu] = useState([
    "Хотели бы вы повысить эффективность и оптимизировать затраты в своем бизнесе, воспользовавшись нашими консультационными услугами по программному обеспечению?",
    "Хотели бы вы пройти обучение в области программного обеспечения, чтобы добиться большего успеха в своей работе?",
    "Хотели бы вы получить техническую помощь, чтобы стать более опытным и эффективным в области программного обеспечения?",
    "Хотели бы вы улучшить обслуживание своих клиентов путем разработки веб-приложений?",
    "Хотели бы вы улучшить обслуживание своих клиентов, разрабатывая приложения для Android?"
  ]);
  const [textTr, setTextTr] = useState([
    "Yazılım danışmanlık hizmetimizden faydalanarak işinizde verimliliği artırmak ve maliyetleri optimize etmek ister misiniz?",
    "Sizi işinizde daha başarılı olmak için yazılım alanlarında eğitmek ister misiniz?",
    "Yazılım alanlarında daha tecrübeli ve etkili olmak için teknik yardım almak ister misiniz?",
    "Web uygulamaları geliştirerek müşterilerinize daha iyi hizmet sunmak ister misiniz?",
    "Android uygulamaları geliştirerek müşterilerinize daha iyi hizmet sunmak ister misiniz?"
  ]);
  const [textUz, setTextUz] = useState([
    "Bizning dasturiy ta'minot bo'yicha maslahat xizmatimizdan foydalanib, biznesingiz samaradorligini oshirish va xarajatlarni optimallashtirishni xohlaysizmi?",
    "Ishingizda muvaffaqiyat qozonishingiz uchun dasturiy ta'minot sohalarida ta'lim olishni xohlaysizmi?",
    "Dasturiy ta'minot sohasida tajribali va samaraliroq bo'lish uchun texnik yordam olishni xohlaysizmi?",
    "Web-ilovalarni ishlab chiqish orqali mijozlaringizga yaxshiroq xizmat ko'rsatishni xohlaysizmi?",
    "Android ilovalarini ishlab chiqish orqali mijozlaringizga yaxshiroq xizmat ko'rsatishni xohlaysizmi?"
  ]);


 

  if (lan === "en") {
    return (
      <div id="ourProducts-en">
        <Container className="marginThero">
          <Row>
            <Col className="col-1 col-xs-1 paddingThero" >
              <button type="button" class="btn productsNextBtnPosition " onClick={productionPrior}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrow-bar-left paddingAboutUsPrior" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5" />
                </svg>
                <p className="card-text control"><b>Prior</b></p>
              </button>
            </Col>
            <Col className="cardAnim col-xs-5 ">
              <div className="card" >
                <img src={photo[card1]} className="card-img-top imgAnim" alt="..." />
                <div className="card-body">
                  <h5 className="card-title h1Tags">{boshliqEn[card1]}</h5>
                  <p className="card-text fontsize pHeight text">{textEn[card1]}</p>
                  <a href="/AboutUsPage" className="btn btn-primary">More</a>
                </div>
              </div>
            </Col>
            <Col className="cardAnim col-xs-5 paddingThero">
              <div className="card" >
                <img src={photo[card2]} class="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title h1Tags">{boshliqEn[card2]}</h5>
                  <p className="card-text fontsize pHeight text">{textEn[card2]}</p>
                  <a href="/AboutUsPage" className="btn btn-primary">More</a>
                </div>
              </div>
            </Col>
            <Col className="cardAnim control ">
              <div className="card" >
                <img src={photo[card3]} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title h1Tags">{boshliqEn[card3]}</h5>
                  <p className=" fontsize pHeight text">{textEn[card3]}</p>
                  <a href="/AboutUsPage" className="btn btn-primary">More</a>
                </div>
              </div>
            </Col>
            <Col className="col-1 col-xs-1 paddingThero">
              <button type="button" class="btn productsNextBtnPosition" onClick={productionNext}>
              
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5" />
                </svg>
                <p className="card-text control"><b>Next</b></p>
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    );

  } if (lan === "ru") {
    return (
      <div id="ourProducts-ru">
        <Container className="marginThero">
          <Row>
            <Col className="col-1 col-xs-1 paddingThero" >
              <button type="button" class="btn productsNextBtnPosition" onClick={productionPrior}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrow-bar-left paddingAboutUsPrior" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5" />
                </svg>
                <p className="card-text control"><b>Прежний</b></p>
              </button>
            </Col>
            <Col className="cardAnim col-xs-5">
              <div className="card" >
                <img src={photo[card1]} className="card-img-top imgAnim" alt="..." />
                <div className="card-body">
                  <h5 className="card-title h1Tags">{boshliqRu[card1]}</h5>
                  <p className="card-text fontsize pHeight">{textRu[card1]}</p>
                  <a href="/AboutUsPage" className="btn btn-primary">Более</a>
                </div>
              </div>
            </Col>
            <Col className="cardAnim col-xs-5 paddingThero">
              <div className="card" >
                <img src={photo[card2]} class="card-img-top" alt="..." />
                <div className="card-body">
                  <h6 className="card-title h1Tags">{boshliqRu[card2]}</h6>
                  <p className="card-text fontsize pHeight">{textRu[card2]}</p>
                  <a href="/AboutUsPage" className="btn btn-primary">Более</a>
                </div>
              </div>
            </Col>
            <Col className="cardAnim control">
              <div className="card" >
                <img src={photo[card3]} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title h1Tags">{boshliqRu[card3]}</h5>
                  <p className="card-text fontsize pHeight">{textRu[card3]}</p>
                  <a href="/AboutUsPage" className="btn btn-primary">Более</a>
                </div>
              </div>
            </Col>
            <Col className="paddingThero col-1  col-xs-1">
              <button type="button" class="btn productsNextBtnPosition" onClick={productionNext}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5" />
                </svg>
                <p className="card-text control"><b>Следующий</b></p>
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } if (lan === "tr") {
    return (
      <div id="ourProducts-tr">
        <Container className="marginThero">
          <Row>
            <Col className="col-1 paddingThero col-xs-1" >
              <button type="button" class="btn productsNextBtnPosition" onClick={productionPrior}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrow-bar-left paddingAboutUsPrior" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5" />
                </svg>
                <p className="card-text control"><b>Önceki</b></p>
              </button>
            </Col>
            <Col className="cardAnim col-xs-5">
              <div className="card" >
                <img src={photo[card1]} className="card-img-top imgAnim" alt="..." />
                <div className="card-body">
                  <h5 className="card-title h1Tags">{boshliqTr[card1]}</h5>
                  <p className="card-text fontsize pHeight">{textTr[card1]}</p>
                  <a href="/AboutUsPage" className="btn btn-primary">Daha</a>
                </div>
              </div>
            </Col>
            <Col className="cardAnim paddingThero col-xs-5">
              <div className="card" >
                <img src={photo[card2]} class="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title h1Tags">{boshliqTr[card2]}</h5>
                  <p className="card-text fontsize pHeight">{textTr[card2]}</p>
                  <a href="/AboutUsPage" className="btn btn-primary">Daha</a>
                </div>
              </div>
            </Col>
            <Col className="cardAnim control">
              <div className="card" >
                <img src={photo[card3]} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title h1Tags">{boshliqTr[card3]}</h5>
                  <p className="card-text fontsize pHeight">{textTr[card3]}</p>
                  <a href="/AboutUsPage" className="btn btn-primary">Daha</a>
                </div>
              </div>
            </Col>
            <Col className="col-1 paddingThero col-xs-1">
              <button type="button" class="btn productsNextBtnPosition" onClick={productionNext}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5" />
                </svg>
                <p className="card-text control"><b>Sonraki</b></p>
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else {
    return (
      <div id="ourProducts-uz">
        <Container className="marginThero">
          <Row>
            <Col className="col-1 paddingThero col-xs-1" >
              <button type="button" class="btn productsNextBtnPosition" onClick={productionPrior}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrow-bar-left paddingAboutUsPrior" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5" />
                </svg>
                <p className="card-text control"><b>Oldingi</b></p>
              </button>
            </Col>
            <Col className="cardAnim col-xs-5">
              <div className="card" >
                <img src={photo[card1]} className="card-img-top imgAnim" alt="..." />
                <div className="card-body">
                  <h5 className="card-title h1Tags">{boshliqUz[card1]}</h5>
                  <p className="card-text fontsize pHeight">{textUz[card1]}</p>
                  <a href="/AboutUsPage" className="btn btn-primary">Ko'proq</a>
                </div>
              </div>
            </Col>
            <Col className="cardAnim paddingThero">
              <div className="card col-xs-5" >
                <img src={photo[card2]} class="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title h1Tags">{boshliqUz[card2]}</h5>
                  <p className="card-text fontsize pHeight">{textUz[card2]}</p>
                  <a href="/AboutUsPage" className="btn btn-primary">Ko'proq</a>
                </div>
              </div>
            </Col>
            <Col className="cardAnim control">
              <div className="card" >
                <img src={photo[card3]} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title h1Tags">{boshliqUz[card3]}</h5>
                  <p className="card-text fontsize pHeight">{textUz[card3]}</p>
                  <a href="/AboutUsPage" className="btn btn-primary">Ko'proq</a>
                </div>
              </div>
            </Col>
            <Col className="col-1 paddingThero col-xs-1">
              <button type="button" class="btn productsNextBtnPosition" onClick={productionNext}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8m-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5" />
                </svg>
                <p className="card-text control"><b>Keyingi</b></p>
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ProductsComponent;
